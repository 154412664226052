import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class SchedulerService
{
    #api = null;
    constructor() {
        this.#api = API_URL + 'admin/scheduler';
    }

    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    getAll(data={},index = null) {
        let url = `${this.#api}/start`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    assignExamDays(data)
    {
        let url = `${this.#api}/assign-exam-days`;
        return apiService.post(url,data);
    }

    createAndAssignCandidates(data){
        let url=`${this.#api}/create/assign-exam-days`;
        return apiService.post(url,data);
    }

}