import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

    export default class CandidateAttachmentService
    {
        #api = null;
        constructor() {
            this.#api = API_URL + 'admin/candidate-attachment';
        }

        show(id) {
            let url = `${this.#api}/${id}`
            return apiService.get(url)
        }
        
        create(data) {
            let url = `${this.#api}`
            return apiService.post(url, data)
        }

        update(id,data) {
            let url = `${this.#api}/${id}`
            return apiService.put(url,data)
        }
        
        delete(examOptionId) {
            let url = `${this.#api}/${examOptionId}`
            return apiService.delete(url)
        }

        getByCandidate(candidateId){
            let url = `${this.#api}/${candidateId}/get-by-candidate`;
            return apiService.get(url);
        }
    }
