<template>
  <v-dialog
      v-model="dialog"
      max-width="1000"
  >
    <v-card>
      <v-toolbar flat>
        <v-card-title>
          <span>Request Refund</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12"  class="mt-5">
              <p class="font-weight-medium subtitle-1">Why would you like to request a refund? *</p>
            </v-col>
            <v-col cols="12" md="12">
              <v-select @change="changeRefundCategory" label="Refund Reason*" dense outlined  v-model="category" :items="refund_categories" :error="$v.refund.category_id.$error"
                        item-text="name" return-object>
                <template v-slot:label>
                  Refund Reason <span class="text-danger">*</span>
                </template>

              </v-select>
              <span class="text-danger" v-if="$v.refund.category_id.$error">This information is required</span>
            </v-col>
            <v-col cols="12" md="12">

                <v-textarea dense outlined  :error="$v.refund.description.$error"
                            v-model="refund.description"
                            label="Refund remarks">
                  <template v-slot:label>
                    Refund remarks <span class="text-danger">*</span>
                  </template>
                </v-textarea>

              <span class="text-danger" v-if="$v.refund.description.$error">This information is required.</span>
            </v-col>
            <v-col cols="12">
              <v-list dense>
                <h5>Refund Request</h5>
                <v-list-item-group >
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <h5 class="font-weight-medium subtitle-1">- Refunds are subject to our Refund Policy</h5>
                      </v-list-item-title>
                      <v-list-item-title>
                        <h5 class="font-weight-medium subtitle-1">- You may be contacted by a member of staff regarding your refund request</h5>
                      </v-list-item-title>

                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox :error="$v.refund.agreement.$error" v-model="refund.agreement"></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          <h6 class="font-weight-medium subtitle-2" v-bind:class="{ 'red--text': $v.refund.agreement.$error }">I agree to Refund Agreement & Confirm request refund for this exam.</h6></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </v-list-item-group>

              </v-list>
            </v-col>


          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>


        <v-btn
            text
            x-large
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn  v-if="!candidate_request_flag"
            x-large
            dark
            @click="createRequestRefund()"
            :loading="loading">
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import SupportCategoryService from "@/services/support/support-category/SupportCategoryService";
import SupportTicketService from "@/services/support/support-ticket/SupportTicketService";
import {required, requiredIf, sameAs} from "vuelidate/lib/validators";

const supportTicket=new SupportTicketService();
const supportCategory=new SupportCategoryService();
export default {
  validations: {
    refund:{
      category_id:{required},
      description:{required},
      agreement:{ sameAs: sameAs( () => true ) }
    }
  },
  data(){
    return{
      dialog:false,
      loading:false,
      candidate_request_flag:false,
      refund_categories:[],
      category:'',
      refund:{
        name:'',
        category_id:'',
        agreement:false,
        candidate_id:'',
        type:'',
        cart_id:'',
        user_id:'',
      },
    }
  },
  methods:{
    closeDialog(){
      this.dialog=false;
      this.refund={
        category_id:'',
        agreement:false,
        candidate_id:'',
        type:'',
        user_id:'',
        score_id:'',
      };
      this.category='';
    },
    requestRefund(item){
      this.refund.name='Appeal Refund';
      this.$v.$reset();
      this.dialog=true;
      this.refund.candidate_id=item.id;
      this.refund.user_id=item.owner_id;
      this.refund.score_id=item.score_id;
      this.refund.cart_id=item.cart_id;
      this.candidate_request_flag=item.check_refund_support_ticket;
      this.refund.type='refund';
      this.getRefundCategories();
    },
    changeRefundCategory(){
      this.refund.category_id=this.category.id;
      this.refund.name=this.category.name;
    },
    getRefundCategories(){
      if(!this.candidate_request_flag) {
        supportCategory
            .getRefundCategories()
            .then(response => {
              this.refund_categories = response.data.refund_support_categories;
            }).catch(err => {

        }).finally(() => {

        });
      }
    },
    createRequestRefund(){

        this.$v.$touch()
        if (this.$v.$error) {
          setTimeout(() => {
            this.$v.$reset()
          }, 3000);
        }
        else {
          this.loading = true;
          supportTicket
              .create(this.refund)
              .then(response => {
                this.$snotify.success("Your refund request has been received.");
                this.$emit('refresh');
                this.closeDialog();
              }).catch(err => {

          }).finally(() => {
            this.loading = false;
          });
        }


    }
  }
}
</script>