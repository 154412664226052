<template>
    <v-dialog
        v-model="dialog"
        max-width="800"
        scrollable
    >
        <v-card>
            <v-toolbar dark>
                <v-card-title>
                <span>Manage Repertoire Information For {{candidate_summary.full_name}} </span>
                <hr>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                <v-spacer></v-spacer>
                </v-toolbar-items>
            </v-toolbar>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <p class="font-size-lg">
                                <strong>Candidate Name :</strong>
                                {{candidate_summary.full_name}}
                            </p>
                            <p class="font-size-lg">
                                <strong>Enrolment key :</strong>
                                {{candidate_summary.exam_key}}
                            </p>
                            <p class="font-size-lg">
                                <strong>Exam  :</strong>
                                {{candidate_summary.exam_name}}
                            </p>
                            <div v-if="! hasAttachments">
                                <p class="font-size-lg mt-4">
                                    Please upload a copy of the candidate’s texts and program information before completing this form. To do this, you will need to add a Candidate Attachment
                                </p>
                                <p class="font-size-lg">
                                    <strong>
                                        Before Uploading – please note
                                    </strong>
                                </p>
                                <ul class="ml-4 font-size-lg">
                                    <li class="mb-3">
                                        All information should be uploaded in 1 PDF file.
                                    </li>
                                    <li class="mb-3">
                                        Uploading should occur 72 hours or more prior to the exam, otherwise the examiner may not receive the information. If you wish to upload a revised version of an attachment, please ensure it is done with this timeframe in mind.
                                    </li>
                                    <li class="mb-3">
                                        The PDF must contain a title page, including the candidate name, Enrolment Key and Exam name, and an outline of the program.
                                        <li class="ml-4 mb-3">
                                            Outlines should specify the program order, information regarding piece type (e.g. memorised poem, reading etc.), title and author.
                                        </li>
                                    </li>

                                </ul>

                                <div v-if="!showFileInput" class="text-right my-1">
                                    <v-btn
                                        color="black black-lighten"
                                        class="text-white"
                                        @click="uploadRepertoire"
                                    >
                                        <v-icon small elevation="2" outlined>fas fa-upload</v-icon>&nbsp; Upload repertoire information
                                    </v-btn>
                                </div>

                                <div v-if="showFileInput" class="my-3 py-7">
                                    <strong class="font-size-lg my-3">Upload a File</strong>
                                    <v-file-input
                                        class="mt-3"
                                        outlined
                                        dense
                                        prepend-icon=""
                                        :error="$v.candidate_attachment.uploadFile.$error"
                                        prepend-inner-icon="mdi-file"
                                        v-model="candidate_attachment.uploadFile"
                                    >
                                    <template slot="label">
                                        Choose File <span class="text-danger">*</span>
                                    </template>
                                    </v-file-input>

                                    <!-- <span class="text-danger" v-if="errors.uploadFile" >**{{errors.uploadFile[0]}}</span> -->
                                    <span class="text-danger" v-if="$v.candidate_attachment.uploadFile.$error" >This information is required</span>

                                    <v-checkbox
                                        v-model="candidate_attachment.accept_requirements"
                                        value
                                    >
                                        <template v-slot:label>
                                            <span class="text-danger">*</span> &nbsp;
                                            I have uploaded the program as per the requirements.
                                        </template>
                                    </v-checkbox>
                                    <span class="text-danger" v-if="$v.candidate_attachment.accept_requirements.$error" >Please check this box before proceeding.</span>


                                    <div class="text-right">
                                        <v-btn
                                            @click="closeDialog"
                                            class="mt-2"
                                            x-large
                                            text
                                        >
                                            Cancel
                                        </v-btn>
                                        <v-btn 
                                            :loading="loading"
                                            class="ml-2 mt-2 btn btn-primary text-white"
                                            @click="saveCandidateAttachment"
                                        >
                                            Submit repertoire
                                        </v-btn>
                                    </div>
                                </div>
                            </div>

                            <div class="mt-5" v-if="hasAttachments">
                                <v-btn
                                    class="text-white"
                                    color="green lighten"
                                    @click="viewPdf(uploaded_candidate_attachment.file_path.thumb)"
                                >
                                    <i class="fa fa-file-pdf text-white"></i> &nbsp;View PDF
                                </v-btn>
                                <v-btn
                                    class="text-white ml-3"
                                    color="red"
                                    :loading="deleteLoading"
                                    @click="deletePdf(uploaded_candidate_attachment.id)"
                                >
                                    <i class="fas fa-trash" style="color: #ffffff"></i> &nbsp;Delete PDF
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import CandidateService from "@/services/candidate/CandidateService";
import CandidateAttachmentService from "@/services/candidate/candidate-attachment/CandidateAttachmentService";
import {required, sameAs} from "vuelidate/lib/validators";

const candidateAttachment = new CandidateAttachmentService();
const candidate = new CandidateService();

export default{
    validations:{
        candidate_attachment:{
            uploadFile: {required},
            accept_requirements: { sameAs : sameAs(() => true)}
        }
    },
    data(){
        return{
            loading: false,
            dialog: false,
            candidate_summary: {},
            showFileInput: false,
            candidate_attachment:{
                uploadFile: '',
                candidate_id: null,
                type: 'repertoire',
                accept_requirements: false
            },
            uploaded_candidate_attachment: '',
            hasAttachments: false,
            deleteLoading: false,
        }
    },
    methods:{
        getCandidateDetail(examKey){
            candidate
            .getCandidateSummary(examKey)
            .then((response) => {  
                this.candidate_summary = response.data.candidate;
                this.getCandidateAttachment();                
            })
            .catch((err) => {
            })
            .finally( () => {
            })
        },
        openDialog(candidate_summary=null, examKey=null){
            if(examKey){
                this.loading = false;
                this.getCandidateDetail(examKey);   
                this.dialog = true;             
            }else{
                this.loading = false;
                this.candidate_summary = candidate_summary;
                this.candidate_attachment.candidate_id = this.candidate_summary.id;
                this.dialog = true;
                this.getCandidateAttachment();
            }
        },
        uploadRepertoire(){
            this.showFileInput = true;
        },
        closeDialog(){
            this.dialog = false;
            this.candidate_attachment = {
                uploadFile: null,
                candidate_id: null,
                type: 'repertoire',
                accept_requirements: false
            };
            this.showFileInput = false;

            this.candidate_summary = {};
            this.showFileInput = false,
            this.uploaded_candidate_attachment = '';
            this.hasAttachments = false;
        },
        convertToFormData() {
            let formData = new FormData();
            for (let key in this.candidate_attachment) {
                if (key === "uploadFile" && this.candidate_attachment[key] != null && this.candidate_attachment[key] != undefined) {
                    formData.append('uploadFile', this.candidate_attachment[key]);
                }else {
                    if(this.candidate_attachment[key]){
                        formData.append(key,this.candidate_attachment[key]);
                    }
                }
            }
            return formData;
        },
        getCandidateAttachment(){
            candidateAttachment
            .getByCandidate(this.candidate_summary.id)
            .then((response) => {
                if(response.data.status == 'ERROR'){

                }else{
                    this.hasAttachments = true;
                    this.uploaded_candidate_attachment = response.data.candidate_attachment;
                }
            })
            .catch((err) => {

            })
            .finally(() => {

            });
        },
        saveCandidateAttachment(){
            this.$v.$touch()
            if (this.$v.$error) {
                setTimeout(() => {
                this.$v.$reset()
                }, 3000);
            }
            else {
                this.loading = true;
                this.candidate_attachment.candidate_id = this.candidate_summary.id;
                let form = this.convertToFormData();

                candidateAttachment
                .create(form)
                .then((response) => {
                    this.$snotify.success('Candidate attachment added');
                    this.closeDialog();
                    this.loading = false;
                    this.$emit('refresh');
                })
                .catch((err) => {
                    this.loading = false;

                    this.$snotify.error('Ooops! attachment could not be added.');
                })
                .finally(() => {
                    this.loading = false;
                });
            }
        },
        viewPdf(file_path){
            if(this.uploaded_candidate_attachment.file_type == 'pdf'){
                window.open(this.uploaded_candidate_attachment.file_path.original, "_blank"); 
            }
            window.open(file_path, "_blank"); 
        },
        deletePdf(candidateAttachmentId){
            this.$confirm({
                message: `Are you sure? `,
                button: {
                    no: "No",
                    yes: "Yes",
                },
                callback: (confirm) => {
                    if (confirm) {
                        this.deleteLoading = true;
                        candidateAttachment
                        .delete(candidateAttachmentId)
                        .then((response) => {
                            this.$snotify.success('Repertoire file deleted');
                            this.closeDialog();
                            this.deleteLoading = false;
                            this.$emit('refresh');
                        })
                        .catch((err) => {
                            this.deleteLoading = false;
                            this.$snotify.error('Ooops! attachment could not be deleted.');
                        })
                        .finally(() => {

                        });
                    }
                }
            });
        }
    }
}
</script>
