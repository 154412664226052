import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class SupportCategoryService {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/support-category';
    }

    paginate(data = {}, index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    create(data) {
        let url = `${this.#api}`;
        return apiService.post(url, data);
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data)
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }

    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }
    sort(data=[]){
        let url = `${this.#api}/sort`;
        return apiService.post(url, data);
    }

    all() {
        let url = `${this.#api}/get/all`;
        return apiService.get(url)
    }

    getVisibleOnlineExam() {
        let url = `${this.#api}/get/support-online-exam`;
        return apiService.get(url);
    }
    getRefundCategories(){
        let url = `${this.#api}/get/refund`;
        return apiService.get(url);
    }
}